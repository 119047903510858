<template>
  <div class="home">
    <div class="navigation">
      <h1 class="navigation__header">BringFuture</h1>
    </div>
    <p class="home__text">Coming soon</p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &__text {
    font-family: "Libre Barcode 39 Text", cursive;
    color: #c2c2c2;
    text-align: center;
    font-size: 52px;
    margin-bottom: auto;
    margin-top: 0;
  }
}

.navigation {
  align-self: flex-start;
  margin-bottom: auto;

  &__header {
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #f7f7f7;
    margin: 0;
    padding: 16px 22px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 540px) {
  .home {
    &__text {
      font-size: 36px;
    }
  }
}
</style>
